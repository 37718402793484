import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Taurus.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Taurus Relationship
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/taurus"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Taurus</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Taurus Relationship</h4>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Taurus Relationship </h1>
                <h2 className="text-sm md:text-base ml-4">Apr 20 - May 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Taurus Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-love");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-nature");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-man");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-traits");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/taurus/taurus-facts");
                  }}>
                  <h5 className="font-semibold text-black">Taurus Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                <p className="playfair text-black text-xl md:text-3xl">Taurus Relationship</p><br/>
                Taurus is a tricky combo of toughness and sensitiveness. When you're in love, it is not easy for you to confess your feeling to your partner. It takes time to declare your genuine feelings and the requirement to make sure that their sentiments and the other person are pretty strong. You are relatively formal in seeking and supporting relationships. Relationships are very important to you; you always keep your partner secure and loved no matter what happens.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Taurus as a lover:</p> You take your own sweet time building a relationship. You are a terrific partner, but you have to understand the other person's psychology before committing anything. Do not throw your love on every other person who knocks on your door. Some people are not suitable for you. Before commenting on any relationship, take time to know each other, it may be that another person is not that interested in you, and you may be suffered from betrayal after some time. You will wait for some time and take time to investigate all the positives and negatives of every step you take. You are secretive and share your feelings as you get along in a relationship. You are in-depth, committed, and devoted in your relationship once you are assured that your partner is equally faithful and trustworthy.
<br/><br/>
 You expect your partner should have the exact wavelength and social standing. You get satisfied and are highly sensual after you find your perfect partner. You tend to show your intense feelings in material ways, such as giving cards, roses, gifts, and going on a dinner date.
<br/><br/>
<p className="font-bold inline-block text-orange-500">Taurus as a colleague:</p> You are all about quality and efficiency in the workplace. You want to dedicate your stable, unwavering energies to any high-quality project to enjoy added return from it. 
<br/><br/>
 You are a natural problem solver, and you are well versed in the art of solving problems. Taurus is always about work satisfaction, and you want to feel that you are doing a good job, even in a small way. <br/><br/>
 You have a reputation for being a team player and always willing to take on any challenge. The atmosphere at the office is one of a relaxed and happy atmosphere, and you will be a pleasure to be around. 
<br/><br/>

You genuinely understand how reliable your work results are. You require an assured setting at the workplace. You put up the fight only if you are enormously confident about your work. 
<br/><br/>
You are honest, reliable, and have a strong sense of responsibility. You are a team leader and can be relied upon to take the blame for the team. You are professional and organized. You are good at your job and are an excellent communicator. You are a decent person. You have a great sense of humor. <br/><br/>
You are a hard worker and always have a plan and a goal. You believe in yourself and your work. You are naturalistic and always do what you say you will do. You are a group player, and you are very dedicated to your work. You never let your feelings get the best of you. You are not easily influenced. You are always ready to give feedback if you are in charge of the work. You strive to grow but manage to deliver consistently. <br/><br/>
You are honest, trustworthy, and have a strong sense of responsibility. These qualities of yours give you a healthy balance between independence and interdependence. At moments your profession may take you to an overly stubborn zone. You are a self-starter and patiently wait for general results. You are hardworking and devoted and will stay in one position and project long. You are practical and tied to be surrounded and have a bond with the beauty of life.<br/><br/>
 You are a good friend and loyal and don't like to talk much. You are an excellent professional worker. You are strong in character. You are the one that others look up to and trust and rely on. You are the kind of person who would prefer to unite with the environment rather than be alone. You are high-spirited and cheerful, and others would rather not have you around.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus as a friend: </p> You are picky in making friends, but it would be for a lifetime once you are in friendship. Your friendship won't get impacted by time, distance, and stubbornness. You get your friends wholeheartedly and make them feel safe in your company. 
<br/><br/>

You take pride in that you can count your genuinely close friends on your fingers. You are faithful, invent deep connections, and are fun with a funny streak. You recall birthdays, anniversaries, and critical events of your friends' life and never ignore giving gifts to them. Being in your inner circle is very difficult as it requires loyalty, trustworthiness, and truthfulness. You want your friend to be honest, encountering, and tolerant of the fun. Your friends can rely upon you all the time and look ahead to your guidance. You go out of your path to help your friends. You tend to get very connected to your friends and occasionally promote high expectations. You advise your friends with the practical inspection without any hyperbole and preconception.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus as a boss:</p> You will maintain good organizational skills. You will be worried about the processes and things to be done uniquely. You will have very heightened criteria and persistently work hard to achieve that goal. You will get abundant opportunities to fulfill your dreams and progress your plans and brilliance in your field. You are practical, pragmatic, extremely patient, easy-going, and have a friendly attitude. You will neither nag things nor fuss about unnecessary items in the office. You are the calm, quiet and polite boss. You are not judgemental and do not hold any prejudice or biases toward your assistants. You will give sufficient time to your associates to learn new things and complete the task. You will also provide fair opportunities to your associates to improve their errors and give them proper time to rest. You can be determined and push your workers to follow specific methods that can be old-fashioned at times. You are not open to testing and taking risks while executing tasks or projects.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus Fathers:</p> You are an affectionate, reliable, dynamic, responsible, and protective father. Your kids will hug and kiss you daily. You will love to turn your kids' ambitions into reality and keep on working for the same. You will have your collection of morals and values for your children. You want your kids to have integrity and learn the importance of living life correctly. You will guide your children to follow in your footsteps. You will teach your children outcomes for their actions, be well adjusted, and maintain high moral values. 
<br/><br/>
You will also instruct/teach them to be outstanding people in life. You will provide good food and nurture your family with amenities and luxuries. 
<br/><br/>
You will protect your kids against the heartbreaking times and provide all possible love and support in difficult times. Your kids will trust you that you will be with them no matter what happens. You are an excellent father figure due to your firm, encouraging, and patient nature.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus Mothers:</p> You are a classic traditional mother. You are an affectionate, protective, and powerful pillar supporting children's interests. You will be attending school concerts, parents' meetings, league matches, plays, or any event in which your kids have partaken. You are a captain of a routine and keep the mealtime schedule, bedtime, study time, playtime, and other daily chores. You are a stable, patient, calm-clear, and sensible mother. You set limitations for kids and make sure they have everything they need. You are soft in the way you discipline the kids. But once you have declined something, you mean it and never say yes to it. You grow your kids to become trustworthy, self-assured, and confident. You are a hardworking mother and regularly handling on high gear for kids. You are slightly less expressive and show your emotions through action instead of words.
<br/><br/>

<p className="font-bold inline-block text-orange-500">Taurus as a Child:</p> You are adorable, affectionate, and cuddly. At the same time, you are strong and cheerful. You have a robust physical appearance and an able mind that help you endear. You thrive on being in a natural environment and undergoing the world through your senses. You make superior use of the five senses and have heightened experience that others can't imagine. You are captivated by sounds, views, and beautiful textures as you grow up. You can make perfect choices in matters of beauty and comfort. You are a food enthusiast and stubbornly refuse to try the things that are not pleading to you. You like to be pampered, swaddled, and held as you feel safe at that time. You will be clingy to the things that keep you attached to the home and loved ones. You are relatively slow and won't get involved in too many actions in a single day. You have your sense of time and perform things at your own pace. You are practical, patient, and intended. You will start in the right way and be involved in building more essential things in life. You are possessive by nature. You enjoy your possessions entirely and won't open them to everyone for fear of losing them.
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">Taurus as a Husband</p><br/>
<p className="font-bold inline-block text-orange-500">Best match for marriage:</p> Best match for marriage: Cancer, Virgo, Scorpio, Capricorn, and Pisces women<br/><br/>
<p className="font-bold inline-block text-orange-500">Read 2020 Horoscope:</p> Taurus marriage, family and child horoscope for 2024<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Taurus as a Wife</p><br/>
<p className="font-bold inline-block text-orange-500">Best match for marriage:</p> Cancer, Virgo, Scorpio, Capricorn, and Pisces Men

<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
